(function($) {
"use strict";

$(document).ready(function($) {
	
	/* Search Toggle
	   ========================================================================== */
	
	$(document).on('click', '#masthead .search-toggle', function(event) {
		event.preventDefault();
		$('#masthead .search-toggle').toggleClass('active');
		$('#masthead .search-form').toggleClass('active');
		$('#masthead .search-field').focus();
	});

	/* Telephone Links
	   ========================================================================== */

	$('.tel[data-phone], .tel .value[data-phone]').each(function() {
		var tel = $(this);
		var link = $('<a/>');

		link.html(tel.html());
		link.attr('href', 'tel:' + tel.data('phone'));
		link.attr('class', tel.attr('class'));

		tel.replaceWith(link);
	});

	/* HTML5 Placeholder
	   ========================================================================== */

	$('input, textarea').placeholder();
	
	/* Slick Slider
	   ========================================================================== */
	
	$('[data-slick]').slick();

	/* Tabset
	   ========================================================================== */

	var tabLinks = $('[data-tab]');
	var	tabContents = $('[data-tab-content]');
	var	tabLinkActive = '.tab-link.active';
	var	tabLinkActiveClass = 'active';
	var	tabActiveClass = 'active';

	$('[data-tabset]').each(function() {
		var wrapper = $(this);
		var group = wrapper.data('tab-group');
		var scope = '[data-tab-group=' + group + ']';
		var tabs = tabLinks.filter(scope);
		var content = tabContents.filter(scope);
		var init = wrapper.data('tab-init');
		
		//
		// Init Tab
		//
		
		$('[data-tab]' + scope).each(function(index, a) {
			if ($(a).attr('href') === window.location.hash) {
				// Do nothing
			}
			else {
				tabs.filter('[data-tab='+ init + ']').addClass(tabLinkActiveClass);
				content.filter('[data-tab-content=' + init + ']').addClass(tabActiveClass);
			}
		});
		
		//
		// Tabs
		//
		
		tabs.on('click', function(event) {
			var tab = $(this);
			var	activeTab = tab.data('tab');
			
			// Toggle active
			if (wrapper.hasClass('tabset-togglable') && tab.hasClass(tabLinkActiveClass)) {
				tabs.removeClass(tabLinkActiveClass).filter('[data-tab='+ activeTab +']').removeClass(tabLinkActiveClass);
				content.removeClass(tabActiveClass).filter('[data-tab-content=' + activeTab + ']').removeClass(tabActiveClass);
			}
			// Show active
			else {
				tabs.removeClass(tabLinkActiveClass).filter('[data-tab='+ activeTab +']').addClass(tabLinkActiveClass);
				content.removeClass(tabActiveClass).filter('[data-tab-content=' + activeTab + ']').addClass(tabActiveClass);
			}
			
			$.fn.matchHeight._update(); // Update match height
			if (tab.hasClass('tab-link') || tab.hasClass('tab-toggle')) {
				event.preventDefault();
			}
		});
		
		//
		// Pevious Tab
		//
		
		wrapper.on('click', '[data-tab-prev]' + scope, function(event) {
			for (var i = tabs.length - 1; i >= 0; i--) {
				var tab = tabs.eq(i);

				if (tab.is(tabLinkActive)) {
					tabs.eq(i - 1).trigger('click');

					break;
				}
			}
			
			$.fn.matchHeight._update(); // Update match height
			event.preventDefault();
		});
		
		//
		// Next Tab
		//
		
		wrapper.on('click', '[data-tab-next]' + scope, function(event) {
			for (var i = 0, last = tabs.length; i < last; i++) {
				var tab = tabs.eq(i);
		
				if (tab.is(tabLinkActive)) {
					tabs.eq((i + 1) % last).trigger('click');
					
					break;
				}
			}
			
			$.fn.matchHeight._update(); // Update match height
			event.preventDefault();
		});
		
		//
		// Hash Tab
		//
		
		$(window).on('hashchange load', function() {
			$('[data-tab]' + scope).each(function(index, a) {
				var tab = $(this);
				var	activeTab = tab.data('tab');
				
				if ($(a).attr('href') === window.location.hash && window.location.href) {
					tabs.removeClass(tabLinkActiveClass).filter('[data-tab='+ activeTab +']').addClass(tabLinkActiveClass);
					content.removeClass(tabActiveClass).filter('[data-tab-content=' + activeTab + ']').addClass(tabActiveClass);
					$(document).scrollTop( $(this).offset().top );
					
					$.fn.matchHeight._update(); // Update match height
				}
			});
		});
	});

	/* Accordion
	   ========================================================================== */

	var accordion = '[data-accordion]';
	var	accordionHeader = '[data-accordion-header]';
	var	accordionContent = '[data-accordion-content]';
	var	accordionActiveClass = 'active';

	$(accordion)
	.find(accordionContent).hide().end()
	.find(accordionHeader +':first').addClass(accordionActiveClass).end()
	.find(accordionContent +':first').show().end()
	.on('click', accordionHeader, function(event) {
		var self = $(this);
		var	accordionBody = self.closest(accordion);

		if (!self.hasClass(accordionActiveClass)) {
			accordionBody
			.find(accordionHeader).removeClass(accordionActiveClass).end()
			.find(accordionContent).slideUp().end();

			self.addClass(accordionActiveClass)
			.next(accordionContent).slideDown(function() {
				$.fn.matchHeight._update(); // Update match height
			}).end();
		}

		event.preventDefault();
	});

	/* Fancybox - Gallery
	   ========================================================================== */

	$('.gallery-thumbnail a').fancybox({
		margin: [44, 0],
		gutter : 32,
		caption : function( instance, item ) {
			return $(this).parent().parent().find('figcaption').html();
		}
	});
	
	/* Video Overlay
	   ========================================================================== */
	
	$('.video-overlay[data-video]').one('click', function() {
		$(this).html('<iframe src="https://www.youtube.com/embed/'+ $(this).data("video") +'?enablejsapi=1&autoplay=1&rel=0" allowfullscreen></iframe>');
	});
		
}); // end.ready

}(jQuery));